import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import BlogNav from "../../components/blog/nav/nav"

import "./post.scss"

export const data = graphql`
  query($slug: String!) {
    dibPosts(slug: { eq: $slug }) {
      title
      summary
      slug
      publishedAt
      readtime
      content
      featuredImage
      categories {
        title
        slug
      }
      author {
        name
        slug
        photo
      }
    }
  }
`

const PostPage = props => {

  const post = props.data.dibPosts

  return (
    <Layout>
      <Seo title={`Blog: ${post.title}`} />
      <div className="container mt-4">
          <div className="blog-header">
            <h2>Blog</h2>
            <BlogNav />
          </div>
          <div className="post-page">
            <h1 className="post-page__title">{post.title}</h1>
            <div className="post-page__author">
              <img alt={post.author.name} src={post.author.photo} />
              <p>
                {post.author.name}·&ndash; {post.publishedAt}
              </p>
            </div>
            <div className='post-page__content' dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </div>
    </Layout>
  )
}

export default PostPage
